import { BrowserRouter, Route, Routes } from "react-router-dom";

// import App from "./app.component";
// import Auth from "./auth/auth.component";
import Home from "src/app/home/home.component";
import Welcome from "src/app/welcome/welcome.component";
import Profile from "src/app/profile/profile.component";
import Events from "src/app/events/events";
import EmptyEvents from "src/app/empty-events/empty-events";
import Appointments from "src/app/appointments/appointments.component";
import Availability from "src/app/availability/availability.component";
import Integrations from "src/app/integrations/integrations";
import Step1 from "src/app/steps/step1";
import Step2 from "src/app/steps/step2";
import Step3 from "src/app/steps/step3";
import Step4 from "src/app/steps/step4";
import Step5 from "src/app/steps/step5";
import Step6 from "src/app/steps/step6";
import Step7 from "src/app/steps/step7";
import DashboardLayout from "src/app/shared/components/layout.component";

// import Logout from "./auth/logout.component";
// import Dashboard from "./dashboard/dashboard.component";
// import { PrivateRoute, NotFound, Unauthorized } from "./shared/components";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/" element={<DashboardLayout />}>
          <Route path="profile" element={<Profile />} />
          <Route path="/events" element={<Events />} />
          <Route path="/empty-events" element={<EmptyEvents />} />
          <Route path="/appointments" element={<Appointments />} />
          <Route path="/availability" element={<Availability />} />
          <Route path="/integrations" element={<Integrations />} />
        </Route>

        <Route path="/welcome" element={<Welcome />} />
        <Route path="/step-1" element={<Step1 />} />
        <Route path="/step-2" element={<Step2 />} />
        <Route path="/step-3" element={<Step3 />} />
        <Route path="/step-4" element={<Step4 />} />
        <Route path="/step-5" element={<Step5 />} />
        <Route path="/step-6" element={<Step6 />} />
        <Route path="/step-7" element={<Step7 />} />
        {/* <Route element={<App />}>
          <Route element={<PrivateRoute />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="logout" element={<Logout />} />
          </Route>
          <Route path="unauthorized" element={<Unauthorized />} />
        </Route> */}
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
