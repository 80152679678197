import React from "react";
import Steps from "src/app/steps";

function Step2() {
  return (
    <div className="overflow-hidden lg:h-screen lg:flex font-dm-sans">
      <section className="overflow-y-auto lg:w-2/5">
        <div className="flex flex-col w-full max-w-2xl min-h-screen px-8 py-12 mx-auto xl:px-16 md:px-10">
          <Steps></Steps>

          <div className="flex flex-col flex-1 mt-[3.5rem]">
            <div>
              <h3 className="text-2xl font-bold text-gray-800">
                Set your Availability
              </h3>

              <p className="mt-2 text-base text-gray-500">When you’re typically available to accept meetings. </p>
            </div>

            <form className="flex flex-col flex-1 mt-6">
            <div className="flex-1">
              <div>
                <h4 className="font-medium text-gray-800">Select Days & Set Time</h4>

                <label className="flex items-center mt-4 space-x-3">
                  <input checked className="p-2 border-gray-300 rounded text-primary" type="checkbox" />
                  <span>Sunday</span>
                </label>

                <div className="flex items-center mt-4 space-x-4">
                  <input type="time" value="05:00" className="block px-3 py-2 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm" />
                  
                  <span>-</span>
                  
                  <input type="time" value="09:00" className="block px-3 py-2 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm" />
                  
                  <button type="button" className="text-gray-500 hover:text-primary">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2.25 4.5H3.75H15.75" stroke="#E21B17" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6 4.5V3C6 2.60218 6.15804 2.22064 6.43934 1.93934C6.72064 1.65804 7.10218 1.5 7.5 1.5H10.5C10.8978 1.5 11.2794 1.65804 11.5607 1.93934C11.842 2.22064 12 2.60218 12 3V4.5M14.25 4.5V15C14.25 15.3978 14.092 15.7794 13.8107 16.0607C13.5294 16.342 13.1478 16.5 12.75 16.5H5.25C4.85218 16.5 4.47064 16.342 4.18934 16.0607C3.90804 15.7794 3.75 15.3978 3.75 15V4.5H14.25Z" stroke="#E21B17" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M7.5 8.25V12.75" stroke="#E21B17" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M10.5 8.25V12.75" stroke="#E21B17" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </button>
                </div>
              </div>

              <button className="flex items-center mt-4 space-x-4 text-gray-800 focus:outline-none">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 3.75V14.25" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M3.75 9H14.25" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                <span className="text-sm ">Add new timeslot for sunday</span>
              </button>

              <hr className="my-6"/>

              <div className="space-y-4 ">
                <label className="flex items-center space-x-3">
                  <input className="p-2 border-gray-300 rounded text-primary" type="checkbox" />
                  <span>Sunday</span>
                </label>

                <label className="flex items-center space-x-3">
                  <input className="p-2 border-gray-300 rounded text-primary" type="checkbox" />
                  <span>Monday</span>
                </label>

                <label className="flex items-center space-x-3">
                  <input className="p-2 border-gray-300 rounded text-primary" type="checkbox" />
                  <span>Tuesday</span>
                </label>

                <label className="flex items-center space-x-3">
                  <input className="p-2 border-gray-300 rounded text-primary" type="checkbox" />
                  <span>Wednesday</span>
                </label>

                <label className="flex items-center space-x-3">
                  <input className="p-2 border-gray-300 rounded text-primary" type="checkbox" />
                  <span>Thursday</span>
                </label>

                <label className="flex items-center space-x-3">
                  <input className="p-2 border-gray-300 rounded text-primary" type="checkbox" />
                  <span>Saturday</span>
                </label>
              </div>
            </div>
              
              <div className="flex items-center mt-12 space-x-4">
                <button className="w-1/2 px-8 py-3 space-x-3 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-white">
                  Back
                </button>

                <button className="w-1/2 px-8 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80">
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>

      <section className="relative items-end hidden w-full lg:flex lg:w-3/5 bg-primary">
        <svg className="absolute bottom-0 right-0" width="525" height="488" viewBox="0 0 525 488" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.1">
            <path d="M2.82046 222.569C-8.92588 178.731 17.0895 133.671 60.9274 121.925L505.431 2.82046C549.269 -8.92588 594.329 17.0895 606.075 60.9274L725.18 505.431C736.926 549.269 710.911 594.329 667.073 606.075L222.569 725.18C178.731 736.926 133.671 710.911 121.925 667.073L2.82046 222.569Z" fill="black"/>
            <path d="M286.801 320.465C332.309 308.271 362.189 328.765 376.439 381.946L402.599 479.578C404.584 486.987 403.895 493.41 400.531 498.849C397.432 504.216 392.046 507.928 384.373 509.984C376.7 512.04 370.048 511.554 364.415 508.526C358.783 505.498 354.974 500.28 352.989 492.871L326.829 395.239C323.071 381.216 317.431 371.81 309.909 367.019C302.58 361.893 292.831 360.961 280.66 364.222C266.372 368.051 256.068 375.632 249.747 386.968C243.691 398.232 242.683 411.405 246.724 426.487L270.439 514.991C272.424 522.399 271.734 528.823 268.371 534.261C265.007 539.7 259.489 543.447 251.816 545.503C244.143 547.559 237.49 547.073 231.858 544.045C226.49 540.946 222.814 535.692 220.829 528.284L157.236 290.95C155.392 284.071 156.152 277.912 159.516 272.474C163.144 266.964 168.663 263.217 176.071 261.232C183.479 259.247 189.964 259.636 195.526 262.4C201.087 265.163 204.789 269.985 206.633 276.864L231.411 369.336C235.26 357.245 241.9 347.1 251.33 338.902C260.954 330.368 272.778 324.222 286.801 320.465Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M473.54 182.747C484.85 183.644 493.292 193.541 492.395 204.851L471.014 474.311C470.117 485.622 460.22 494.063 448.91 493.166C437.599 492.268 429.158 482.372 430.055 471.061L451.435 201.601C452.333 190.291 462.229 181.849 473.54 182.747Z" fill="white"/>
            </g>
        </svg>

        <div className="max-w-lg px-12 py-12">
            <div className="flex items-center space-x-3">
                <button className="w-6 h-2.5 bg-white rounded-full"></button>
                <button className="w-2.5 h-2.5 bg-white bg-opacity-40 rounded-full"></button>
                <button className="w-2.5 h-2.5 bg-white bg-opacity-40 rounded-full"></button>
            </div>

            <h2 className="mt-12 text-4xl font-bold text-white">Be Efficient with Effective Scheduling</h2>

            <p className="mt-6 text-lg text-white/80">Integrate with your existing calendars to serve your customers</p>
        </div>
      </section>
    </div>
  );
}

export default Step2;
