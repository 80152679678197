import produce from "immer";
import create, { State } from "zustand";
import { devtools, persist } from "zustand/middleware";
import appConstants from "../../app/shared/config";
import { IAuthInfo, IUserInfo } from "../../interfaces";

interface AuthState extends State {
  token: string;
  user: IUserInfo;
  setAuthState: (x: IAuthInfo) => void;
}

const useAuthStore = create<AuthState>(
  persist(
    devtools(
      (set) => ({
        token: null,
        user: null,
        setAuthState: (data) =>
          set(
            produce((state) => {
              state.token = data ? data.token : null;
              state.user = data ? data.user : null;
            }),
            false,
            "auth/setAuth"
          ),
      }),
      { name: "auth", serialize: { options: true } }
    ),
    {
      name: appConstants.keys.auth, // unique name
      getStorage: () => appConstants.keys.storage, // (optional) by default, 'localStorage' is used
    }
  )
);

export default useAuthStore;
