import React from "react";

const Header = (props) => {
  return (
    <header className="bg-white border-b">
        <nav className="flex items-center justify-between h-20 px-4 lg:px-8 sm:px-6">
            <h4 className="text-lg font-semibold text-gray-800 2xl:text-xl">{ props.title }</h4>

            <button onClick={() => props.setSidebarOpen(true)} className="text-gray-600 lg:hidden focus:outline-none focus:text-primary">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            </button>

            { props.children }
        </nav>
    </header>
  );
}

export default Header;
