import React from "react";
import ReactDOM from "react-dom";
import { setContext } from '@apollo/client/link/context';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink
} from "@apollo/client";

import { IntlProvider } from "react-intl";
import AppRouter from "./app/app-router.component";
import { ErrorBoundary } from "./app/shared/components";
import { DEFAULT_LOCALE, LOCALES, messages } from "./i18n";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

function getUserLocale(): string {
  let userLocale =
    (navigator.languages && navigator.languages[0]) || navigator.language;

  if (!userLocale) {
    return DEFAULT_LOCALE;
  }

  userLocale = userLocale.split("-")[0];
  const isValid = Object.keys(LOCALES).some(
    (key) => LOCALES[key] === userLocale
  );
  return isValid ? userLocale : DEFAULT_LOCALE;
}

const locale = getUserLocale();

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImY5RC1wZklrNFVhTHlTdjQzZ2tNSSJ9.eyJnaXZlbl9uYW1lIjoiQW5raXQiLCJmYW1pbHlfbmFtZSI6Ikt1bWFyIiwibmlja25hbWUiOiJhbmtpdCIsIm5hbWUiOiJBbmtpdCBLdW1hciIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BQVRYQUp5TlUwYlBIUW1JZlpJNGVmVEFOVHRQbEMwLTlmUTdRa3h6VlRlTT1zOTYtYyIsImxvY2FsZSI6ImVuIiwidXBkYXRlZF9hdCI6IjIwMjItMDMtMTJUMDM6MDk6MTEuNjg0WiIsImVtYWlsIjoiYW5raXRAcXVvcHBvLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJpc3MiOiJodHRwczovL3N0YWdpbmctaGx0aGRldi51cy5hdXRoMC5jb20vIiwic3ViIjoiZ29vZ2xlLW9hdXRoMnwxMDMwNTEwMjQwNDU4Mzc2MjU1MTAiLCJhdWQiOiJMMWxtMnQ4cDlYOU96ZU1obzRqVExZUlRwd1dMNkszMiIsImlhdCI6MTY0NzA5NDE2MCwiZXhwIjoxNjQ3MTMwMTYwLCJub25jZSI6IlMwcEZaR1Z6Wm1OU1kybEtNVnAxTlVKbFFWQklhMkp2WmpaWVYwTndUSGx3VDBsUVJrRkdUbTFEVHc9PSJ9.Ai4pSZ3egmiex4w_n_rS_8oaENgeBG71mulZJAhHXSxKIkdJtt-XMRP4Ar8bR0KEDRcw9kyEOqOwz0VqBGqF3nifpPDGl9dV-k_5lSYbOUX-qQaeRHYcqhbP6gQblQrLcJ9BNOFBwViYT_ghRpIxcdTaulx0fcBLSym3m0wV1aJXURHMDD27iI1aKl_Y_1-w3fpM9eOD_UZb-G-mKd6R2KiQ8C0kHHif8OsrOsbmHEhhDnaL1-jxE0P_-ZHzEAHa1l1-9I_zhA2TnbpB85OHYR0Be6mLwLMpJ6laDWbDcuD9U6-yEy9e425C-hR5tsymTTI9_yV-J00zYD0gLPUkcA'
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const httpLink = createHttpLink({
  uri: 'https://dev-scheduler-api.onrender.com/api/graphql',
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <IntlProvider
        locale={locale}
        defaultLocale={DEFAULT_LOCALE}
        messages={messages[locale]}
      >
        <ApolloProvider client={client}>
          <AppRouter />
        </ApolloProvider>
      </IntlProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
