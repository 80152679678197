import React, { useState } from "react";
import Header from "src/app/header.component";
import Toggle from "src/app/toggle.component";
import CreateIntegrations from "src/app/integrations/create";
import googleCalendar from "src/assets/images/image 28.png";
import office from "src/assets/images/image 32.png";
import Calendarcom from "src/assets/images/image 42.png";
import zoom from "src/assets/images/image 44.png";
import slack from "src/assets/images/image 45.png";
import Chrome from "src/assets/images/image 48.png";

const Integrations = () => {
  const [createIntegrationsOpen, setCreateIntegrationsOpen] = useState(false);

  return (
    <>
      <CreateIntegrations createIntegrationsOpen={createIntegrationsOpen} setCreateIntegrationsOpen={setCreateIntegrationsOpen} />

      <div className="flex flex-col flex-1 min-h-screen overflow-hidden bg-gray-100">
        <Header title="Integrations" />

        <main className="flex-1 px-4 py-8 space-y-12 overflow-y-auto lg:px-8 sm:px-6">
          <div>
            <h2 className="text-xl font-medium text-gray-800 capitalize">Calendar apps</h2>
            <p className="mt-2 text-base text-gray-600">Schedule Appointments, Gather availability and sync your calendar in OpenScheduling.</p>

            <div className="w-full max-w-6xl p-6 mt-6 bg-white rounded-lg">
              <div className="sm:flex sm:items-center sm:justify-between">
                <div className="flex items-center space-x-4">
                  <img className="h-8" src={googleCalendar} alt="" />
                  <h3 className="text-lg font-medium text-gray-700">Google Calendar</h3>
                </div>

                <button className="flex items-center mt-4 space-x-2 text-sm text-gray-500 sm:mt-0 focus:outline-none hover:text-primary">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 3.75V14.25" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M3.75 9H14.25" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>

                  <span>Add New Account</span>
                </button>
              </div>

              <hr className="my-8" />

              <div className="grid grid-cols-1 gap-6 mt-6 sm:grid-cols-2 xl:grid-cols-4">
                <div>
                  <label className="text-sm text-gray-400">Email</label>

                  <p className="mt-2 text-base font-medium text-gray-800">michellefrank@gmail.com</p>
                </div>

                <div>
                  <label className="text-sm text-gray-400">Connection Status</label>

                  <div className="flex items-center mt-2 space-x-2 text-base text-yellow-500">
                    <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.86001 2.57323L1.21335 11.9999C1.09693 12.2015 1.03533 12.4301 1.03467 12.6629C1.03402 12.8957 1.09434 13.1246 1.20963 13.3269C1.32492 13.5292 1.49116 13.6977 1.69182 13.8158C1.89247 13.9339 2.12055 13.9973 2.35335 13.9999H13.6467C13.8795 13.9973 14.1076 13.9339 14.3082 13.8158C14.5089 13.6977 14.6751 13.5292 14.7904 13.3269C14.9057 13.1246 14.966 12.8957 14.9654 12.6629C14.9647 12.4301 14.9031 12.2015 14.7867 11.9999L9.14001 2.57323C9.02117 2.3773 8.85383 2.21531 8.65414 2.10288C8.45446 1.99046 8.22917 1.9314 8.00001 1.9314C7.77086 1.9314 7.54557 1.99046 7.34588 2.10288C7.1462 2.21531 6.97886 2.3773 6.86001 2.57323V2.57323Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M8 6V8.66667" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M8 11.3333H8.00667" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                    <span>Not Active</span>
                  </div>
                </div>

                <div>
                  <label className="text-sm text-gray-400">Last Sync</label>

                  <p className="mt-2 text-base font-medium text-gray-800 ">8:00AM · 1 Feb, 2020</p>
                </div>

                <div className="flex items-end">
                  <button onClick={() => setCreateIntegrationsOpen(true)}>
                    <Toggle />
                  </button>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-6 mt-8 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
              <div className="flex items-center justify-between p-6 bg-white rounded-lg">
                <div className="flex items-center space-x-4">
                  <img className="h-8" src={Calendarcom} alt="" />
                  <h3 className="text-lg font-medium text-gray-700 truncate">Calendar.com</h3>
                </div>

                <button onClick={() => setCreateIntegrationsOpen(true)}>
                  <Toggle />
                </button>
              </div>

              <div className="flex items-center justify-between p-6 bg-white rounded-lg">
                <div className="flex items-center space-x-4">
                  <img className="h-8" src={office} alt="" />
                  <h3 className="text-lg font-medium text-gray-700 truncate">Office 365 Calen...</h3>
                </div>

                <button onClick={() => setCreateIntegrationsOpen(true)}>
                  <Toggle />
                </button>
              </div>
            </div>
          </div>

          <div>
            <h2 className="text-xl font-medium text-gray-800 capitalize">Notification Apps</h2>
            <p className="mt-2 text-base text-gray-600">Schedule Appointments, Gather availability and sync your calendar in OpenScheduling.</p>

            <div className="grid grid-cols-1 gap-6 mt-8 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
              <div className="p-6 bg-white rounded-lg">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-4">
                    <img className="h-8" src={slack} alt="" />
                    <h3 className="text-lg font-medium text-gray-700 truncate">Slack </h3>
                  </div>

                  <button onClick={() => setCreateIntegrationsOpen(true)}>
                    <Toggle />
                  </button>
                </div>

                <p className="mt-6 text-gray-500">Receive notification and manage your calendar in slack</p>

                <button className="flex items-center justify-between w-full mt-6 text-sm font-medium tracking-wider text-gray-700 uppercase" onClick={() => setCreateIntegrationsOpen(true)}>
                  <span>View Triggers</span>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.75 9H14.25" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M9 3.75L14.25 9L9 14.25" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div>
            <h2 className="text-xl font-medium text-gray-800 capitalize">Conversation Booking Apps</h2>
            <p className="mt-2 text-base text-gray-600">Schedule Appointments, Gather availability and sync your calendar in OpenScheduling.</p>

            <div className="grid grid-cols-1 gap-6 mt-8 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
              <div className="p-6 bg-white rounded-lg">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-4">
                    <img className="h-8" src={zoom} alt="" />
                    <h3 className="text-lg font-medium text-gray-700 truncate">Zoom</h3>
                  </div>

                  <button onClick={() => setCreateIntegrationsOpen(true)}>
                    <Toggle />
                  </button>
                </div>

                <p className="mt-6 text-gray-500">Automatically include zoom meeting details in your events</p>

                <button className="flex items-center justify-between w-full mt-6 text-sm font-medium tracking-wider text-gray-700 uppercase" onClick={() => setCreateIntegrationsOpen(true)}>
                  <span>View Triggers</span>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.75 9H14.25" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M9 3.75L14.25 9L9 14.25" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </button>
              </div>

              <div className="p-6 bg-white rounded-lg">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-4">
                    <img className="h-8" src={Chrome} alt="" />
                    <h3 className="text-lg font-medium text-gray-700 truncate">Chrome </h3>
                  </div>

                  <button onClick={() => setCreateIntegrationsOpen(true)}>
                    <Toggle />
                  </button>
                </div>

                <p className="mt-6 text-gray-500">Update Mailchimp contacts when meetings are scheduled</p>

                <button className="flex items-center justify-between w-full mt-6 text-sm font-medium tracking-wider text-gray-700 uppercase" onClick={() => setCreateIntegrationsOpen(true)}>
                  <span>View Triggers</span>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.75 9H14.25" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M9 3.75L14.25 9L9 14.25" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default Integrations;
