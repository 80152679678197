import { useState } from "react";
import Header from "src/app/header.component";
import CreateEvent from "src/app/events/create";

const EmptyEvents = () => {
  const [createEventOpen, setCreateEventOpen] = useState(false);
  
  return (
    <>
      <CreateEvent createEventOpen={createEventOpen} setCreateEventOpen={setCreateEventOpen} />

      <div className="flex flex-col flex-1 min-h-screen overflow-hidden bg-gray-100">
        <Header title="Empty Events">
          <button onClick={() => setCreateEventOpen(true)} className="items-center hidden space-x-2 font-medium lg:flex focus:outline-none text-primary hover:text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>
            <span>Create New Event</span>
          </button>
        </Header>

        <main className="flex flex-col items-center justify-center flex-1 space-y-4 overflow-y-auto">
            <div className="px-4 py-8 text-center sm:px-6">
                <h2 className="text-3xl font-medium text-gray-800 sm:text-4xl">Create Your First Event!</h2>
                <p className="max-w-md mt-4 text-gray-500">Add your event name and description, Select Timeslots & Payment Link (Optional)</p>

                <button onClick={() => setCreateEventOpen(true)} className="inline-flex items-center px-6 py-3 mt-6 space-x-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                  </svg>

                  <span>Create New Event</span>
                </button>
            </div>
        </main>
    </div>
    </>
  );
}

export default EmptyEvents;
