/* eslint-disable */

import { initializeApp } from 'firebase/app'
import {
  GoogleAuthProvider,
  GithubAuthProvider,
  getAuth,
  signInWithPopup,
  setPersistence,
  browserLocalPersistence,
  signOut,
} from 'firebase/auth'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
}

initializeApp(firebaseConfig)
const googleAuthProvider = new GoogleAuthProvider()
const githubAuthProvider = new GithubAuthProvider()

googleAuthProvider.addScope('https://www.googleapis.com/auth/contacts.readonly')

const auth = getAuth()

export const googleSignin = () => {
  setPersistence(auth, browserLocalPersistence)
    .then(() => {
      signInWithPopup(auth, googleAuthProvider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          // const credential = GoogleAuthProvider.credentialFromResult(result);
          // const token = credential?.accessToken;

          const user = result?.user

          return user
        })
        .catch((error) => {
          // Handle Errors here.
          // const { code, message, email } = error
          // The AuthCredential type that was used.
          // const credential = GoogleAuthProvider.credentialFromError(error);
        })
    })
    .catch((error) => {
      // Handle Errors here.
      // const { code, message } = error
    })
}

export const githubSignin = () => {
  setPersistence(auth, browserLocalPersistence)
    .then(() => {
      signInWithPopup(auth, githubAuthProvider)
        .then((result) => {
          const user = result?.user

          return user
        })
        .catch((error) => {
          // Handle Errors here.
          // const { code, message, email } = error
        })
    })
    .catch((error) => {
      // Handle Errors here.
      // const { code, message } = error
    })
}

export const userLogout = () => {
  signOut(auth)
    .then(() => {
      // successful.
    })
    .catch((error) => {
      new Error(error)
    })
}