import { useState } from 'react'

import Sidebar from "src/app/sidebar.component";
import { Outlet } from 'react-router-dom';

const DashboardLayout = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    return (
        <div className="h-screen bg-gray-100 lg:overflow-hidden lg:flex font-dm-sans">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            <Outlet />
        </div>
    )
}

export default DashboardLayout