import React, { useState } from "react";
import Header from "src/app/header.component";
import AvailabilityCard from "src/app/availability/card";
import AvailabilityCreate from "src/app/availability/create";


const Availability = () => {
  const [createAvailabilityOpen, setCreateAvailabilityOpen] = useState(false);
  
  return (
    <>
      <AvailabilityCreate createAvailabilityOpen={createAvailabilityOpen} setCreateAvailabilityOpen={setCreateAvailabilityOpen} />
      
      <div className="flex flex-col flex-1 min-h-screen overflow-hidden bg-gray-100">
        <Header title="Availability">
          <button onClick={() => setCreateAvailabilityOpen(true)} className="items-center hidden space-x-2 font-medium lg:flex focus:outline-none text-primary hover:text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>
            <span>Create New Availability</span>
          </button>
        </Header>

        <main className="flex-1 px-4 py-8 space-y-4 overflow-y-auto lg:px-8 sm:px-6">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
            <AvailabilityCard setCreateAvailabilityOpen={setCreateAvailabilityOpen} />
            <AvailabilityCard setCreateAvailabilityOpen={setCreateAvailabilityOpen} />
            <AvailabilityCard setCreateAvailabilityOpen={setCreateAvailabilityOpen} />
            <AvailabilityCard setCreateAvailabilityOpen={setCreateAvailabilityOpen} />
          </div>
        </main>
    </div>
    </>
  );
}

export default Availability;
