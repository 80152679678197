const storageTypes = {
  local: localStorage,
  session: sessionStorage,
};

const keys = {
  auth: "authInfo",
  storage: storageTypes.session,
};

const urls = {
  baseUrl: "https://reqres.in/api",
  login: `login`,
  logger: `logger`,
};

const appConstants = {
  keys,
  urls,
};

export default appConstants;
