import React from "react";

const createAvailability = (props) => {
  return (
    <div className="text-gray-800 bg-gray-100 font-dm-sans">
        <div onClick={() => props.setCreateAvailabilityOpen(false)} className={"fixed inset-0 z-40 transition-opacity bg-black opacity-50 " + (props.createAvailabilityOpen ? 'block' : 'hidden')}></div>

        <div className={ "fixed inset-y-0 right-0 z-50 flex flex-col h-screen overflow-y-auto transition duration-200 transform bg-white border-r border-gray-200 w-full sm:w-[23rem] " + (props.createAvailabilityOpen ? 'translate-x-0 ease-in' : 'translate-x-full ease-out')}>
            <div className="flex items-center justify-between px-6 mt-6 space-x-4 sm:space-x-0">
                <h3 className="text-2xl font-medium text-gray-800">Create Availability</h3>

                <button onClick={() => props.setCreateAvailabilityOpen(false)} className="text-gray-600 focus:text-primary focus:outline-none">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5 4.5L4.5 13.5" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M4.5 4.5L13.5 13.5" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>

            <main className="px-6 py-4">
                <form>
                    <div>
                        <label className="text-sm font-medium text-gray-600">Availability Name </label>
                        
                        <input type="text" placeholder="Working Hours" className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm" />
                    </div>

                    <div className="mt-8">
                        <label className="text-sm font-medium text-gray-600">Time zone</label>
                        
                        <select className="block w-full py-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm">
                            <option>UTC-08:00 Anchorage</option>
                            <option>test</option>
                            <option>test</option>
                            <option>test</option>
                        </select>
                    </div>

                    <div className="mt-8">
                        <label className="text-sm font-medium text-gray-600">Set your weekly hours</label>
                        
                        <label className="flex items-center mt-4 space-x-3">
                            <input checked className="p-2 border-gray-200 rounded-md text-primary" type="checkbox" />
                            <span>Sunday</span>
                        </label>

                        <div className="flex items-center mt-4 space-x-4">
                            <button type="button" className="text-gray-500 hover:text-primary">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>
                            </button>

                            <input type="time" className="block px-3 py-2 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm" />
                            <span>-</span>
                            <input type="time" className="block px-3 py-2 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm" />
                        </div>

                        <button type="button" className="flex items-center mt-4 space-x-2 text-gray-400 hover:text-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                            </svg>
                            
                            <span>Add new timeslot for sunday</span>
                        </button>
                    </div>

                    <hr className="my-8"/>

                    <div className="space-y-4 ">
                        <label className="flex items-center space-x-3">
                            <input className="p-2 border-gray-200 rounded-md text-primary" type="checkbox" />
                            <span>Monday</span>
                        </label>

                        <label className="flex items-center space-x-3">
                            <input className="p-2 border-gray-200 rounded-md text-primary" type="checkbox" />
                            <span>Tuesday</span>
                        </label>

                        <label className="flex items-center space-x-3">
                            <input className="p-2 border-gray-200 rounded-md text-primary" type="checkbox" />
                            <span>Wednesday</span>
                        </label>

                        <label className="flex items-center space-x-3">
                            <input className="p-2 border-gray-200 rounded-md text-primary" type="checkbox" />
                            <span>Thursday</span>
                        </label>

                        <label className="flex items-center space-x-3">
                            <input className="p-2 border-gray-200 rounded-md text-primary" type="checkbox" />
                            <span>Friday</span>
                        </label>

                        <label className="flex items-center space-x-3">
                            <input className="p-2 border-gray-200 rounded-md text-primary" type="checkbox" />
                            <span>Saturday</span>
                        </label>
                    </div>
                    
                    <div className="flex items-center justify-end mt-8 space-x-4">
                        <button className="w-1/2 px-8 py-2 font-medium text-center text-gray-700 transition-colors duration-200 transform border rounded-md hover:bg-white">
                            Cancel
                        </button>
                        <button className="w-1/2 px-6 py-2 font-medium text-center text-white capitalize transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80">
                            Save
                        </button>
                    </div>
                </form>
            </main>
        </div>
    </div>
  );
}

export default createAvailability;
